<template>
  <section ref="slider" class="slider">
    <p v-if="null !== title && title.length > 0" class="slider__title">
      {{ title }}
    </p>
    <div class="slider__wrapper">
      <ul
        ref="items"
        class="slider__items"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <SlideProduct
          v-for="product in products"
          :id="idTag + product.id"
          :key="product.id"
          class="slider__item"
          :product="product"
        />
      </ul>
      <div class="slider__pagination">
        <PaginationBtnArrow
          class="slider__prev"
          :direction-right="false"
          :disabled="counter === 0"
          @click="prevSlide"
        />

        <PaginationBtnArrow
          class="slider__next"
          :direction-right="true"
          :disabled="buttonDisabled"
          @click="nextSlide"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import PaginationBtnArrow from "~~/modules/shared/components/buttons/PaginationBtnArrow.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import SlideProduct from "~/modules/shared/components/productCard/SlideProduct.vue";

const props = defineProps({
  products: { type: Array, required: true },
  title: { type: [String, null], required: false, default: null },
  idTag: { type: String, required: false },
});

const items = ref(null);
const slider = ref(null);

const slideWidth = ref(0);
const spaceSlides = ref(0);
const distance = ref(0);
const translateX = ref(0);
const counter = ref(0);
const translateXVar = ref(0);
const buttonDisabled = ref(true);

const mobileTranslateX = ref(0);
const difference = ref(0);
const activeTouches = ref(false);
const startPosition = ref(0);

function getSizeSlide() {
  slideWidth.value = items.value.children[0].offsetWidth;
  spaceSlides.value = parseInt(getComputedStyle(items.value).gap);
}

function nextSlide() {
  const sliderWidth = items.value.scrollWidth;
  const sliderWindow = slider.value.offsetWidth;
  const slidesLength = props.products.length;

  if (slideWidth.value * slidesLength > sliderWindow) {
    const maxStep = Math.ceil(slidesLength - sliderWindow / slideWidth.value);
    distance.value =
      sliderWidth - sliderWindow - (translateX.value + slideWidth.value);

    if (distance.value >= 0 && counter.value < maxStep - 1) {
      counter.value++;
      translateX.value = (slideWidth.value + spaceSlides.value) * counter.value;
      translateXVar.value = `-${translateX.value}px`;
    } else {
      translateX.value = sliderWidth + spaceSlides.value - sliderWindow;
      counter.value = maxStep;
      translateXVar.value = `-${translateX.value}px`;
    }

    buttonDisabled.value = !(counter.value < maxStep);
  }
}

function prevSlide() {
  const sliderWidth = items.value.scrollWidth;
  const sliderWindow = slider.value.offsetWidth;
  const startingPosition = 0;
  const slidesLength = props.products.length;
  const maxStep = Math.ceil(slidesLength - sliderWindow / slideWidth.value);
  distance.value =
    sliderWidth - sliderWindow - (translateX.value - slideWidth.value);

  if (distance.value <= sliderWidth - sliderWindow) {
    counter.value--;
    translateX.value = (slideWidth.value + spaceSlides.value) * counter.value;
    translateXVar.value = `-${translateX.value}px`;
  } else {
    counter.value = 0;
    translateX.value = startingPosition;
    distance.value = sliderWidth - sliderWindow;
    translateXVar.value = `-${translateX.value}px`;
  }

  buttonDisabled.value = !(counter.value < maxStep);
}

function checkSliderLength() {
  buttonDisabled.value =
    slideWidth.value * props.products.length < slider.value.offsetWidth;
}

function handleTouchStart(event) {
  activeTouches.value = true;
  mobileTranslateX.value = event.touches[0].clientX;
  startPosition.value = event.touches[0].clientX;
}

function handleTouchMove(event) {
  const positionMove = event.touches[0].clientX;
  const diff = positionMove - mobileTranslateX.value;
  const fingerSpace = 30;

  if (
    startPosition.value - positionMove < fingerSpace &&
    startPosition.value - positionMove > -fingerSpace
  ) {
    return false;
  } else if (activeTouches.value) {
    if (!mobileTranslateX.value) {
      return false;
    }

    difference.value = diff;
    difference.value > 0 ? prevSlide() : nextSlide();

    mobileTranslateX.value = null;
  }
}

function handleTouchEnd() {
  activeTouches.value = false;
}

onMounted(() => {
  getSizeSlide();
  checkSliderLength();

  addScopedEventListener(window, "resize", checkSliderLength);
  addScopedEventListener(window, "resize", getSizeSlide);
  addScopedEventListener(window, "resize", prevSlide);
});
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;

  overflow: hidden;

  &__title {
    @include font(24, 30, 700);
    letter-spacing: 0.02em;
    color: #363636;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(20, 28, 400);
      text-align: center;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__pagination {
    width: 100%;

    @include flex-container(row, space-between, center);

    position: absolute;
    top: 50%;
    z-index: 80;

    transform: translateY(-50%);
  }

  &__prev,
  &__next {
    position: absolute;
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }

  &__items {
    width: 100%;

    @include flex-container(row, flex-start);

    gap: 7px;

    transition: transform 0.3s ease-in-out;
    transform: translateX(v-bind(translateXVar));
  }

  &__item {
    flex: 0 0 254px;

    @include bigMobile {
      flex: 0 0 164px;
    }
  }
}
</style>
